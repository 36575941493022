import React, { Component } from 'react';

class Header extends Component {

    constructor(props) {
        super(props);
        
        var service = "Home";
        switch (window.location.pathname) {
            case "/": service = "Home"; break;
            case "/services": service = "Services"; break;
            case "/gallery": service = "Gallery"; break;
            case "/contact": service = "Contact"; break;
            default: service = "Home";
        }    

        this.state = {
            isHome: service === "Home",
            isServices: service === "Services",
            isGallery: service === "Gallery",
            isContact: service === "Contact",
        };

    }

    render() {
        return (
            
        <header id="header">
            <div className="logo">
                <img src="/assets/images/logo.svg" alt="logo" />
            </div>
            <div className="content">
                <div className="inner">
                    <h1>RM Aspire</h1>
                    <p>We only do what we can be fully proud of.<br /> Quality over quantity is our mantra.</p>
                </div>
            </div>
            <nav>
                <ul>
                    <li><a href="/" className={this.state.isHome ? 'active' : ''}>Home</a></li>
                    <li><a href="/services" className={this.state.isServices ? 'active' : ''}>Services</a></li>
                    <li><a href="/gallery" className={this.state.isGallery ? 'active' : ''}>Gallery</a></li>
                    <li><a href="/contact" className={this.state.isContact ? 'active' : ''}>Contact</a></li>
                </ul>
            </nav>
        </header>

        );
    }

}

export default Header;