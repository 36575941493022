import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';

class Gallery extends Component {

    constructor() {
        super();
        this.state = {
            images: [],
            category: null
        }
    }

    componentDidMount() {
        fetch('/content/images.json')
        .then(result => {
            return result.json();
        })
        .then(data => {
            const images = data.map((i) => ({original: i.imageURL, thumbnail: i.thumbURL, description: i.description, category: i.category}));
            this.setState({images: images});
        })
    }

    setCategory = e => {
        const category = e.target.value === 'All' ? null : e.target.value;
        this.setState({category: category});
        this._imageGallery.slideToIndex(0);
    }

    render() {

        let galleryItems = [];
        if (this.state.category === null) {
            galleryItems = this.state.images;
        } else {
            galleryItems = this.state.images.filter((i) => this.state.category === i.category);
        }

        return (

            <article id="gallery">
                <h2 className="major">Gallery</h2>

                <div className="gallery-buttons">
                    <button className={this.state.category === null ? 'primary' : 'secondary'} onClick={this.setCategory} value="All">All</button>
                    <button className={this.state.category === 'Interiors' ? 'primary' : 'secondary'} onClick={this.setCategory} value="Interiors">Interiors</button>
                    <button className={this.state.category === 'Exteriors' ? 'primary' : 'secondary'} onClick={this.setCategory} value="Exteriors">Exteriors</button>
                </div>
                
                <ImageGallery items={galleryItems} lazyLoad={true} showFullscreenButton={false} ref={i => this._imageGallery = i} />

            </article>

        );        
    }

}

export default Gallery;