import React, { Component } from 'react';
import Carousel from 'nuka-carousel';

class Home extends Component {

    constructor() {
        super();
        this.state = {
            content: null,
            testimonials: []
        }
    }

    componentDidMount() {
        fetch('/content/content.json')
        .then(result => {
            return result.json();
        })
        .then(data => {
            this.setState({content: data.homepage, testimonials: data.testimonials});
        })
    }

    render() {

        const content = this.state.content ? <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div> : <div className="spinner"></div>;

        return (

            <article id="home">

                <h2 className="major">Home</h2>
                <span className="image main"><img src="/assets/images/home.jpg" alt="" /></span>
                {content}
                {this.state.testimonials.length > 0 && 
                    <Carousel 
                        autoplay={true} 
                        wrapAround={true} 
                        renderCenterLeftControls={null} 
                        renderCenterRightControls={null} 
                        heightMode="max">

                        {this.state.testimonials.map((t,i) => <div className="carousel-item" key={i}>{t}</div>)}

                    </Carousel>
                }
            </article>

        );
    }
  
}

export default Home;