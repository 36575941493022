import React, { Component } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import ReactDOM from 'react-dom';

import './App.css';
import "react-image-gallery/styles/css/image-gallery.css";

class App extends Component {

  scroll = event => {

    const arrow = ReactDOM.findDOMNode(this.refs.arrow);
    function scrollTo(element, to, duration) {

        if (duration <= 0) return;
        var difference = to - element.offsetTop;
        var perTick = difference / duration * 10;

        setTimeout(function() {
            window.scrollTo(0, perTick);
            if (element.offsetTop >= to) return;
            scrollTo(element, to, duration - 10);
        }, 20);
    }

    setTimeout(function() {
        scrollTo(arrow, arrow.offsetTop + 400, 250);
    });

  };

  render() {

    return (

        <Router>
            <div id="wrapper">
                <Header />                     
                <div id="main">

                    <div className="arrow" onClick={this.scroll} ref="arrow"></div>

                    <Route exact path="/" component={Home} />
                    <Route path="/services" component={Services} />
                    <Route path="/gallery" component={Gallery} />
                    <Route path="/contact" component={Contact} />

                </div>
                <Footer />
            </div>  
        </Router>
    );
  }
}

export default App;
