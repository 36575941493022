import React, { Component } from 'react';

class Services extends Component {

    constructor() {
        super();
        this.state = {
            content: null,
        }
    }

    componentDidMount() {
        fetch('/content/content.json')
        .then(result => {
            return result.json();
        })
        .then(data => {
            this.setState({content: data.services});
        })
    }

    render() {

        const content = this.state.content ? <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div> : <div className="spinner"></div>;

        return (

            <article id="services">
                <h2 className="major">Services</h2>
                <span className="image main"><img src="/assets/images/services.jpg" alt="" /></span>
                {content}
            </article>

        );
    }

}

export default Services;