import React, { Component } from 'react';

class Contact extends Component {

    constructor() {
        super();
        this.state = {
            content: null,
            errorField: null,
            errorMessage: null,
            charactersLeft: 56,
            isFormSent: false,
            payloadName:null,
            payloadMobile:null,
            payloadMessage: "I'd like to discuss the project with you, please give me a call.",
            defaultMessage: "I'd like to discuss the project with you, please give me a call.",
            isBusy:false
        }
    }

    componentDidMount() {
        fetch('/content/content.json')
        .then(result => {
            return result.json();
        })
        .then(data => {
            this.setState({content: data.contact});
        })
    }

    handleChange = e => {
        const key = e.target.name;
        if (key === "Message") {
            let charactersCount = 120 - e.target.value.length;
            if (charactersCount < 0) charactersCount = 0;
            this.setState({payloadMessage: e.target.value, charactersLeft: charactersCount});
            return;
        }
        this.setState({['payload'+key]: e.target.value});
    }

    isFormValid() {

        if (null === this.state.payloadName || this.state.payloadName.length < 2 || this.state.payloadName.length > 20) {
            this.setState({errorField: 'name', errorMessage: 'Please enter your name'});
            return false;
        }

        const mobileRX = /^0[1-9][0-9]{9}$/;
        if (!mobileRX.test(this.state.payloadMobile)) {
            this.setState({errorField: 'mobile', errorMessage: 'Please enter your mobile number'});
            return false;
        }

        if (null === this.state.payloadMessage || this.state.payloadMessage.length < 5) {
            this.setState({errorField: 'message', errorMessage: 'Please enter your message'});
            return false;
        }

        if (this.state.payloadMessage.length > 120) {
            this.setState({errorField: 'message', errorMessage: 'Please keep your message short (max 120 characters)'});
            return false;
        }

        this.setState({errorField: null, errorMessage: null});
        return true;

    }

    handleSubmit = e => {
        e.preventDefault();
        if (!this.isFormValid()) {
            return;
        }

        this.setState({errorField: null, errorMessage: null, isBusy: true});
        const payload = {name: this.state.payloadName, mobile: this.state.payloadMobile, message: this.state.payloadMessage, serviceKey: 'b494b194-bf5a-4972-83b0-e15611182e94' };
        let formData = new FormData();
        formData.append('name', payload.name);
        formData.append('mobile', payload.mobile);
        formData.append('message', payload.message);
        formData.append('serviceKey', payload.serviceKey);

        fetch("/contact-us.php", {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(() => {
            this.setState({isBusy: false, isFormSent: true});
        }).catch(error => {            
            this.setState({isBusy: false});
            console.log(error);
        });

    }

    resetForm = e => {
        this.setState({payloadMessage: this.state.defaultMessage, payloadName: null, payloadMobile: null, charactersLeft: 56});
    }

    render() {

        const content = this.state.content ? <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div> : <div className="spinner"></div>;

        return (

            <article id="contact">
                <h2 className="major">Contact</h2>
                {!this.state.isFormSent &&
                    <form onSubmit={this.handleSubmit}>
                        <div className="fields">
                            <div className="field half">
                                <label htmlFor="Name">Name</label>
                                <input type="text" name="Name" id="Name" placeholder="John" onChange={this.handleChange} />
                                {this.state.errorField === "name" && <span className="error">{this.state.errorMessage}</span>}
                            </div>
                            <div className="field half">
                                <label htmlFor="Mobile">Mobile</label>
                                <input type="text" name="Mobile" id="Mobile" placeholder="07512345678" onChange={this.handleChange} />
                                {this.state.errorField === "mobile" && <span className="error">{this.state.errorMessage}</span>}
                            </div>
                            <div className="field">
                                <label htmlFor="Message">Message</label>
                                <span className="form-hint">Characters left: {this.state.charactersLeft}</span>
                                <textarea name="Message" id="Message" rows="4" defaultValue={this.state.defaultMessage} onChange={this.handleChange}></textarea>
                                {this.state.errorField === "message" && <span className="error">{this.state.errorMessage}</span>}
                            </div>
                        </div>
                        <ul className="actions">
                            <li><input type="submit" value={this.state.isBusy ? "Sending..." : "Send Message"} className="primary" disabled={this.state.isBusy} /></li>
                            {!this.state.isBusy && <li><input type="reset" value="Reset" onClick={this.resetForm} /></li>}
                        </ul>
                    </form>
                }
                {this.state.isFormSent && 
                    <p>Thanks! We'll be in touch shortly...</p>
                }
                {content}
            </article>

        );
    }
  
}

export default Contact;