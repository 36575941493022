import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (

        <footer id="footer">
            <p className="copyright">&copy; RM Aspire Construction. All rights reserved.</p>
        </footer>
        
    );
  }
}

export default Footer;